<template>
    <div>
        <app-layout>
	        <template v-slot:header>
	            <Header :title="$t('requests_of_leave_with_consent')"
	                    :isColumns="true"
	                    @filter-div-status="datatable.filterStatus=$event">
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('requests_of_leave_with_consent')"
		                      :isColumns="true"
		                      @filter-div-status="datatable.filterStatus=$event">
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </HeaderMobile>
	        </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter" @filterClear="filterClear"
                :export-excel="checkPermission('leavewithconsent_excelexport')"
                @exportExcel="downloadExcel">
                <b-row>
                    <b-col sm="12" lg="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="4">
                        <b-form-group :label="$t('department')">
                            <department-selectbox v-model="datatable.queryParams.filter.department_code"
                                                  :faculty_code="datatable.queryParams.filter.faculty_code"></department-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="4">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                               :faculty_code="datatable.queryParams.filter.faculty_code"
                                               :department_code="datatable.queryParams.filter.department_code"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('request_number')">
                            <b-form-input type="number" size="sm" v-model="datatable.queryParams.filter.id" v-check-min-max-value/>
                        </b-form-group>
                    </b-col>
<!--                    <b-col sm="6" md="6" lg="3" xl="3">-->
<!--                        <b-form-group :label="$t('request_date')">-->
<!--                            <b-form-input type="date" size="sm" v-model="datatable.queryParams.filter.created_at"></b-form-input>-->
<!--                        </b-form-group>-->
<!--                    </b-col>-->
                    <b-col sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="number" size="sm" v-model="datatable.queryParams.filter.student_number" v-check-min-max-value></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.name"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('surname')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.surname"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="6">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year"></academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="6">
                        <b-form-group :label="$t('reason')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.reason" code="leave_with_consent_reasons"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="6">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.status" code="leave_with_consent_statuses"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="6">
                        <b-form-group :label="$t('student_group')">
                            <multi-selectbox v-model="datatable.queryParams.filter.student_group"
                                             :options="studentGroupOptions"
                                             :multiple="false" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="6">
                        <b-form-group :label="$t('request_date_range')" class="mb-0">
                            <v-date-picker v-model="localDateFilter" is-range>
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 mr-1">
                                                <input :value="inputValue.start" v-on="inputEvents.start" :placeholder="$t('start_date')" class="form-control" />
                                            </b-form-group>
                                        </div>
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 ml-1">
                                                <input :value="inputValue.end" v-on="inputEvents.end" :placeholder="$t('end_date')" class="form-control" />
                                            </b-form-group>
                                        </div>
                                    </div>
                                </template>
                            </v-date-picker>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="6">
                        <b-form-group :label="$t('registration_type')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.registration_type"
                                                 code="registration_types"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="6">
                        <b-form-group :label="$t('class')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.class"
                                                 code="classes"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       @on-show-detail-button-click="onShowDetailButtonClick($event)"
                       v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout.vue'
    import Header from '@/layouts/AppLayout/Header.vue'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile.vue'

    // Component
    import AcademicYearsSelectbox from '@/components/interactive-fields/AcademicYearsSelectbox.vue';
    import Datatable from '@/components/datatable/Datatable.vue';
    import DatatableFilter from '@/components/datatable/DatatableFilter.vue';
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox.vue';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox.vue';
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox.vue';
    import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

    // Services
    import LeaveWithConsentService from '@/services/LeaveWithConsentService';

    // Other
    import qs from 'qs'
    import ApprovalStepService from "@/services/ApprovalStepService";
    import moment from "moment";
    export default {
        components: {
            AppLayout,
            Header,
	        HeaderMobile,
            DatatableFilter,
            Datatable,
            FacultySelectbox,
            ProgramSelectbox,
            ParameterSelectbox,
            AcademicYearsSelectbox,
            MultiSelectbox
        },
        metaInfo() {
            return {
                title: this.$t('requests_of_leave_with_consent')
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons:[
                                {
                                    text: this.$t('detail'),
                                    class: 'ri-search-eye-line',
                                    permission: "leavewithconsent_show",
                                    callback:(row)=>{

                                        window.open('/leave/with/consents/' + row.id, '_blank');
                                    }
                                },
                                {
                                    text: this.$t('do_cancel'),
                                    class: 'ri-close-circle-line',
                                    permission: "approvalstep_cancel",
                                    show: (row) => {
                                        if(row.active_step){
                                            return true;
                                        }
                                        return false;
                                    },
                                    callback: (row) => {
                                        this.$swal.fire({
                                            text: this.$t('are_you_sure_to_cancel'),
                                            showCancelButton: true,
                                            confirmButtonText: this.$t('yes'),
                                            cancelButtonText: this.$t('no')
                                        })
                                            .then((result) => {
                                                if (result.isConfirmed) {
                                                    ApprovalStepService.cancel(row.active_step.id).then((response)=>{
                                                        this.filter();
                                                    });
                                                }
                                            })
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "leavewithconsent_delete",
                                    callback: (row) => {
                                        this.formDelete(row.id)
                                    },
                                }
                            ]
                        },
                        {
                            label: this.$t('request_number').toUpper(),
                            field: 'id',
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center'
                        },
                        {
                            label: this.$t('request_date').toUpper(),
                            field: 'created_at',
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('student_number').toUpper(),
                            field: 'student_number',
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center'
                        },
                        {
                            label: this.$t('name').toUpper(),
                            field: 'name',
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center'
                        },
                        {
                            label: this.$t('surname').toUpper(),
                            field: 'surname',
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center'
                        },
                        {
                            label: this.$t('faculty').toUpper(),
                            field: this.$i18n.locale=='tr' ? 'faculty_name':'faculty_name_en',
                            hidden: true,
                            thClass: 'text-center'
                        },
                        {
                            label: this.$t('program').toUpper(),
                            field: this.$i18n.locale=='tr' ? 'program_name':'program_name_en',
                            hidden: false,
                            thClass: 'text-center'
                        },
                        {
                            label: this.$t('academic_year').toUpper(),
                            field: 'academic_year',
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center'
                        },
                        {
                            label: this.$t('reason').toUpper(),
                            field: this.getLocaleField('reason_text'),
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center'
                        },
                        {
                            label: this.$t('status').toUpper(),
                            field: this.getLocaleField('status_text'),
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center'
                        },
                        {
                            label: this.$t('explanation').toUpper(),
                            field: 'explanation',
                            hidden: true,
                            tdClass: 'text-center',
                            thClass: 'text-center'
                        },

                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: '-id',
                        page: 1,
                        limit: 20
                    }
                },

                localDateFilter: {
                    start: null,
                    end: null
                },

                studentGroupOptions: [
                    {
                        text: 'TR',
                        value: 'TR'
                    },
                    {
                        text: 'YU',
                        value: 'YU'
                    }
                ]
            }
        },
        created() {
            this.filterSet()
        },
        methods: {
            filterSet(){
                this.datatable.queryParams.filter = {
                    id: null,
                    created_at: null,
                    student_number: null,
                    name: null,
                    surname: null,
                    academic_year: null,
                    reason: null,
                    faculty_code: null,
                    program_code: null,
                    status: null,
                    registration_type: null,
                };
            },
            filterClear() {
                this.datatable.queryParams.page = 1;
                this.localDateFilter = {};
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key){
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden==true ? false:true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                if (this.localDateFilter && this.localDateFilter.start && this.localDateFilter.end) {
                    this.datatable.queryParams.filter.created_at_greater_than = moment(this.localDateFilter.start).format("YYYY-MM-DD");
                    this.datatable.queryParams.filter.created_at_smaller_than = moment(this.localDateFilter.end).format("YYYY-MM-DD");
                }

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return LeaveWithConsentService.getAll(config)
                                          .then((response) => {
                                              this.datatable.rows = response.data.data
                                              this.datatable.total = response.data.pagination.total
                                          })
                                          .finally(() => {
                                              this.datatable.isLoading = false;
                                          });
            },
            downloadExcel(){
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                LeaveWithConsentService.downloadAsExcel(config)
                    .then(res=>this._downloadFile(res,this.$t('requests_of_leave_with_consent')+'.xlsx'))
                    .catch(err=>this.showErrors(err))
            },
            formDelete(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no'),
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            LeaveWithConsentService.del(id)
                                .then(response => {
                                    this.$toast.success(this.$t('api.' + response.data.message));
                                    this.getRows();
                                })
                                .catch(error => {
                                    this.$toast.error(this.$t('api.' + error.data.message));
                                });
                        }
                    })
            },

        }
    };
</script>

